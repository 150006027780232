@tailwind base;
@tailwind components;
@tailwind utilities;



@font-face {
  font-family: 'Mona Sans';
  font-weight: 200 900;
  font-display: block;
  font-style: normal;
  font-stretch: 75% 125%;
  src: url('./fonts/Mona-Sans.var.woff2') format('woff2');
}


.Snackbar_snackbar-wrapper__ocbPJ.Snackbar_snackbar-wrapper-top-center__1Gbfi{

  z-index: 9999;


}
  form {
    /* width: 30vw;
    min-width: 500px; */
    align-self: center;
    box-shadow: 0px 0px 0px 0.5px rgba(50, 50, 93, 0.1),
      0px 2px 5px 0px rgba(50, 50, 93, 0.1), 0px 1px 1.5px 0px rgba(0, 0, 0, 0.07);
    border-radius: 7px;
    padding: 40px;

  }

  .absolute.bg-white.dark\:bg-black.w-full.h-full.top-0.left-0.rounded-lg.drop-shadow-md.-z-10.mb-4{
    display: none;
  }
  
  .sm\:flex-row-important {
    display: flex !important;
    flex-direction: row !important;

  }
  
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  /* Buttons and links */
    .paymentButton {
    background: #5469d4;
    font-family: Arial, sans-serif;
    color: #ffffff;
    border-radius: 4px;
    border: 0;
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: block;
    transition: all 0.2s ease;
    box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
    width: 100%;
  }
  .table-container {

  }
  
  .fixed-width {
    width: 160px;
  }
  button:hover {
    filter: contrast(115%);
  }
  /* .relative{
    width: fit-content;
  } */
  button:disabled {
    opacity: 0.5;
    cursor: default;
  }
  
  /* spinner/processing state, errors */
  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: '';
  }
  
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #5469d4;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #5469d4;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }
  
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  
  @media only screen and (max-width: 600px) {
    form {
      width: 100%;
      min-width: initial;
    }
  }


  .chat-container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.messages {
  display: flex;
  flex-direction: column; /* Flip the order of messages */
  overflow-y: auto;
  flex-grow: 1; /* Grow to fill container */
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 10px;
}




.message-content {
    margin: 0;
    color: #333; /* Dark text for readability */
}

.message-timestamp {
    margin: 0;
    font-size: 0.8em;
    color: #666; /* Lighter text for timestamp */
}

.message-input {
    display: flex;
    padding: 10px;
    background-color: #fff;
}

.message-input input {
    flex-grow: 1;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.message-input button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.message-input button:hover {
    background-color: #0056b3;
}

.message {
  margin-bottom: 10px;
  padding: 10px;
  background-color: #e5e5ea; /* Light gray background for receivers */
  border-radius: 10px;
  align-self: flex-start; /* Align non-sender messages to the left */
}

.message.sender {
  background-color: #d1fcd3; /* Light green background for sender */
  align-self: flex-end; /* Align sender messages to the right */
}

.message.reciever {
  background-color: #a99108; /* Light green background for sender */
  align-self: flex-start; /* Align sender messages to the right */
}


div#headlessui-dialog-panel-\:r1\:{
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border: 1px solid #e5e7eb;
  outline: none;
}

.PhoneInputInput {
  box-shadow: none!important;
  border: none!important;
}
.konvajs-content{
  background: aliceblue;
}
div#headlessui-dialog-panel-\:r1\:{
  align-items: flex-start;
  justify-content: flex-start;
}
.store-selection {
  display: flex;
  overflow-x: auto;
  padding: 10px;
  gap: 10px;
}
.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.store {
  flex: 0 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  min-width: 100px;
  text-align: center;
}

.rdp-months {
  display: flex;
  justify-content: center;
}