.search-box {
    position: relative;
    width: 100%;

    margin: 0 auto;
  }
  
  .search-input {
    width: 80%;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    outline: none;
    margin: 0 auto;
    display: block;
  }
  
  .search-results {
    position: absolute;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
  }
  
  .search-result {
    padding: 10px;
    font-size: 14px;
    border-bottom: 1px solid #eee;
    cursor: pointer;
  }
  
  .search-result:last-child {
    border-bottom: none;
  }
  
  .search-result:hover {
    background-color: #f5f5f5;
  }
  